// Copyright 2022 Dustin Barker. All rights reserved
import React from 'react';
import './App.css';

const NUM_DIGITS = 3; // FIXME: doesn't handle != 3
const NUM_GUESSES = 6;
const INITIAL_RESULTS = [];
const INITIAL_SOLVED_STATE = false;
const INITIAL_STREAK = 0;

class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mark: 0
    };
  }

  updateMark() {
    if (this.props.completed) {
      this.setState((state) => {
        return { mark: (state.mark + 1) % 4 };
      });
    }
  }

  render() {
    const marks = ["e", "f", "p", "t"];
    const mark = this.props.solved ? this.props.result : marks[this.state.mark];
    const className = "square mark-" + mark;
    return (
      <button className={className} onClick={() => this.updateMark()}>
        {this.props.value}
      </button>
    );
  }
}

class Board extends React.Component {
  render() {
    const guesses = this.props.guesses;
    const numRemaining = NUM_GUESSES - guesses.length - 1;
    const remaining =
      numRemaining > 0
        ? [...Array(numRemaining)].map((k, v) => {
            const offsetIndex = guesses.length + v;
            return offsetIndex;
          })
        : [];
    const results = this.props.results;
    const guess = this.props.guess;
    const lastIndex =
      this.props.solved || numRemaining === -1 ? guesses.length - 1 : -1;

    return (
      <div className="guesses">
        {guesses.map((guess, i) => (
          <CurrentRow
            key={i}
            guess={guess}
            completed={true}
            solved={i === lastIndex}
            results={results[i]}
          />
        ))}
        <InputRow guess={guess} guesses={guesses} />
        {remaining.map((i) => (
          <CurrentRow
            key={i}
            guess=""
            completed={false}
            results={["e", "e", "e"]}
          />
        ))}
      </div>
    );
  }
}

function stringToArray(string) {
  return string.length === 0
    ? []
    : string.length > 1
    ? string.split("")
    : [string];
}

class CurrentRow extends React.Component {

  render() {
    const guess = this.props.guess;
    const splitGuess = stringToArray(guess);
    const results = this.props.results.filter((e) => true);
    const completed = this.props.completed;
    const solved = this.props.solved;
    return (
      <div className="guess">
        {[...Array(NUM_DIGITS)].map((letter, i) => (
          <Cell
            key={i}
            value={splitGuess[i]}
            completed={completed}
            solved={solved}
            result={results[i]}
          />
        ))}
        <ResultIndicator result={results.sort().join("")} />
      </div>
    );
  }
}

class Key extends React.Component {
  render() {
    const onKey = this.props.onKey;
    const className =
      "key " + (this.props.className ? this.props.className : "");
    const onClick = () => {
      onKey(this.props.value);
    };
    return (
      <button className={className} onClick={onClick}>
        {this.props.value}
      </button>
    );
  }
}

function StatusBar(props) {
  const streakClassName = "status-button streak s" + Math.min(props.streak, 9);
  const button = props.button ? (
    <button className="status-button reset" onClick={props.onClick}>
      {props.button}
    </button>
  ) : null;
  return (
    <div className="status-bar">
      <div className={streakClassName}>streak: {props.streak}</div>
      <div className="status-message">{props.message}</div>
      {button}
    </div>
  );
}

function ResultStatusBar(props) {
  if (props.solved) {
    return (
      <StatusBar
        streak={props.streak}
        message="solved!"
        button="next"
        onClick={props.next}
      />
    );
  } else if (props.failed) {
    const answer = "answer is " + props.answer;
    return (
      <StatusBar
        streak={props.streak}
        message={answer}
        button="reset"
        onClick={props.reset}
      />
    );
  } else {
    return <StatusBar streak={props.streak} />;
  }
}

class Keyboard extends React.Component {
  render() {
    return (
      <div className="keyboard">
        <div className="digits">
          {[...Array(9).keys()].map((value) => (
            <Key key={value + 1} value={value + 1} onKey={this.props.onKey} />
          ))}
          <Key
            key="enter"
            value="ENTER"
            onKey={this.props.onEnter}
            className="enter"
          />
          <Key key="0" value="0" onKey={this.props.onKey} />
          <Key key="backspace" value="⌫" onKey={this.props.onKey} />
        </div>
      </div>
    );
  }
}

function InputRow(props) {
  if (props.guesses.length < NUM_GUESSES) {
    return (
      <CurrentRow
        guess={props.guess}
        completed={false}
        results={["e", "e", "e"]}
      />
    );
  }
  return null;
}

function ResultIndicator(props) {
  const className = "pie " + props.result;
  return <div className={className} />;
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.random(NUM_DIGITS),
      guess: "",
      guesses: [],
      results: INITIAL_RESULTS,
      solved: INITIAL_SOLVED_STATE,
      failed: false,
      streak: INITIAL_STREAK
    };
  }

  filter(digits, excluded) {
    return digits.filter((digit) => {
      return !excluded.includes(digit);
    });
  }

  randomWithMinMax(min, max) {
    return Math.floor(min + Math.random() * (max - min)).toString();
  }

  random(numDigits) {
    const digits = [...Array(10)].map((o, i) => {
      return i;
    });
    var answer = [];
    [...Array(numDigits)].map((o, i) => {
      const availableDigits = this.filter(
        digits,
        answer.length === 0 ? [0] : answer
      );
      const randomIndex = this.randomWithMinMax(0, availableDigits.length - 1);
      answer.push(availableDigits[randomIndex]);
    });
    const answerString = answer.join("");
    return answerString;
  }

  onKey(key) {
    this.setState((state) => {
      var updated = state.guess;
      if (key === "⌫") {
        updated = updated.slice(0, -1);
      } else if (state.guess.length >= NUM_DIGITS) {
        console.log("maximum input reached");
        return;
      } else {
        updated = updated += key;
      }
      return { guess: updated };
    });
  }

  onEnter() {
    if (this.state.guess.length < NUM_DIGITS) {
      return;
    }

    this.setState((state) => {
      const guesses = state.guesses.concat([state.guess]);
      const solved = state.guess === state.answer;
      const failed = !solved && guesses.length >= NUM_GUESSES;
      const streak = solved ? state.streak + 1 : state.streak;
      const results = guesses.map((guess) => {
        return this.check(guess);
      });
      return {
        guesses: guesses,
        results: results,
        guess: "",
        solved: solved,
        failed: failed,
        streak: streak
      };
    });
  }

  check(guess) {
    const splitGuess = stringToArray(guess);
    const answer = this.state.answer;
    const splitAnswer = answer.split("");
    var result = splitGuess.map((digit, i) => {
      if (digit == splitAnswer[i]) {
        return "t";
      } else if (splitAnswer.includes(digit)) {
        return "p";
      } else {
        return "f";
      }
    });
    console.log(
      "guess: " + splitGuess + ", answer: " + splitAnswer + " result " + result
    );
    return result;
  }

  next() {
    this.setState((state) => {
      return {
        guess: "",
        guesses: [],
        answer: this.random(NUM_DIGITS),
        solved: false,
        failed: false,
        results: []
      };
    });
  }

  reset() {
    this.setState((state) => {
      return {
        guess: "",
        guesses: [],
        answer: this.random(NUM_DIGITS),
        solved: false,
        failed: false,
        streak: 0,
        results: []
      };
    });
  }

  render() {
    return (
      <div className="game">
        <div className="header">
          <ResultStatusBar
            solved={this.state.solved}
            failed={this.state.failed}
            answer={this.state.answer}
            streak={this.state.streak}
            next={() => this.next()}
            reset={() => this.reset()}
          />
        </div>
        <Board
          guess={this.state.guess}
          guesses={this.state.guesses}
          results={this.state.results}
          solved={this.state.solved}
        />
        <div className="spacer" />
        <Keyboard
          onKey={(key) => {
            this.onKey(key);
          }}
          onEnter={() => {
            this.onEnter();
          }}
        />
      </div>
    );
  }
}

function App() {
  return (
    <div className="App">
     <Game />
    </div>
  );
}

export default App;
